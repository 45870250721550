import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { gapi } from 'gapi-script';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';

// クライアントIDとAPIキーを設定します（Google Cloud Consoleから取得）
// const CLIENT_ID = 'YOUR_CLIENT_ID.apps.googleusercontent.com';
const CLIENT_ID = '749567168465-3o1g8oq0imtv1pajro0esrdhshsigeum.apps.googleusercontent.com';
const API_KEY = 'AIzaSyB6_7HgNCSm5Q8eDTZnOLx1GOTeV5Aqx8M';

const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

const App = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [title, setTitle] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
        scope: SCOPES,
      }).then(() => {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        // Handle the initial sign-in state.
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const updateSigninStatus = (isSignedIn) => {
    setIsAuthorized(isSignedIn);
  };

  const handleAuthClick = () => {
    gapi.auth2.getAuthInstance().signIn({
      redirect_uri: 'https://antares.scorpio.tokyo/callback'  // ここで redirect_uri を指定
    });
  };

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    if (!selectedDates.find(el => el.date === formattedDate)) {
      setSelectedDates([...selectedDates, { date: formattedDate, title: title || '未設定' }]);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const removeDate = (dateToRemove) => {
    setSelectedDates(
      selectedDates.filter((entry) => entry.date !== dateToRemove)
    );
  };

  const addToGoogleCalendar = async () => {
    try {
      for (const entry of selectedDates) {
        const event = {
          summary: entry.title,
          start: { date: entry.date },
          end: { date: entry.date },
        };
        await gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: event,
        });
      }
      alert('予定がGoogleカレンダーに追加されました');
    } catch (error) {
      console.error('Error adding event to Google Calendar', error);
      alert('予定を追加する際にエラーが発生しました');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>日付と予定を選択する</h2>
        <div className="custom-input-container">
          <input 
            type="text" 
            value={title} 
            onChange={handleTitleChange} 
            placeholder="予定のタイトルを入力" 
            className="title-input"
          />
        </div>
        <div className="custom-datepicker-container">
          <DatePicker
            selected={null}
            onChange={handleDateChange}
            inline
            highlightDates={selectedDates.map(entry => new Date(entry.date))}
            dayClassName={date => 
              selectedDates.find(entry => entry.date === moment(date).format('YYYY-MM-DD'))
                ? 'highlighted-date'
                : !title ? 'grayed-out-date' : undefined
            }
            disabled={!title}
          />
        </div>
        <h2>選択された日付と予定:</h2>
        <ul>
          {selectedDates.map((entry, index) => (
            <li key={index}>
              {entry.date} - {entry.title}
              <button
                onClick={() => removeDate(entry.date)}
                style={{ marginLeft: '10px' }}
              >
                削除
              </button>
            </li>
          ))}
        </ul>
        <div className="custom-button-container">
          {isAuthorized ? (
              <button onClick={addToGoogleCalendar}>Googleカレンダーに追加</button>
            ) : (
              <button onClick={handleAuthClick}>Googleにサインイン</button>
            )}
        </div>
      </header>
    </div>
  );
};

export default App;
